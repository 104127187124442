exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-art-handoff-js": () => import("./../../../src/pages/blog/art-handoff.js" /* webpackChunkName: "component---src-pages-blog-art-handoff-js" */),
  "component---src-pages-blog-continuous-review-js": () => import("./../../../src/pages/blog/continuous-review.js" /* webpackChunkName: "component---src-pages-blog-continuous-review-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-customer-terms-js": () => import("./../../../src/pages/customer-terms.js" /* webpackChunkName: "component---src-pages-customer-terms-js" */),
  "component---src-pages-desktop-gdc-js": () => import("./../../../src/pages/desktop/gdc.js" /* webpackChunkName: "component---src-pages-desktop-gdc-js" */),
  "component---src-pages-desktop-index-js": () => import("./../../../src/pages/desktop/index.js" /* webpackChunkName: "component---src-pages-desktop-index-js" */),
  "component---src-pages-ecgc-2023-for-education-js": () => import("./../../../src/pages/ecgc-2023-for-education.js" /* webpackChunkName: "component---src-pages-ecgc-2023-for-education-js" */),
  "component---src-pages-ecgc-2023-js": () => import("./../../../src/pages/ecgc-2023.js" /* webpackChunkName: "component---src-pages-ecgc-2023-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-for-art-leads-js": () => import("./../../../src/pages/for-art-leads.js" /* webpackChunkName: "component---src-pages-for-art-leads-js" */),
  "component---src-pages-for-artists-js": () => import("./../../../src/pages/for-artists.js" /* webpackChunkName: "component---src-pages-for-artists-js" */),
  "component---src-pages-for-engineers-js": () => import("./../../../src/pages/for-engineers.js" /* webpackChunkName: "component---src-pages-for-engineers-js" */),
  "component---src-pages-for-producers-js": () => import("./../../../src/pages/for-producers.js" /* webpackChunkName: "component---src-pages-for-producers-js" */),
  "component---src-pages-game-caviar-js": () => import("./../../../src/pages/game-caviar.js" /* webpackChunkName: "component---src-pages-game-caviar-js" */),
  "component---src-pages-get-a-demo-js": () => import("./../../../src/pages/get-a-demo.js" /* webpackChunkName: "component---src-pages-get-a-demo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index-old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-landing-page-js": () => import("./../../../src/pages/landing-page.js" /* webpackChunkName: "component---src-pages-landing-page-js" */),
  "component---src-pages-podcast-js": () => import("./../../../src/pages/podcast.js" /* webpackChunkName: "component---src-pages-podcast-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-pricing-deprecated-js": () => import("./../../../src/pages/pricing-deprecated.js" /* webpackChunkName: "component---src-pages-pricing-deprecated-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-showcase-new-content-nav-js": () => import("./../../../src/pages/showcase-new/ContentNav.js" /* webpackChunkName: "component---src-pages-showcase-new-content-nav-js" */),
  "component---src-pages-showcase-new-icons-listen-js": () => import("./../../../src/pages/showcase-new/icons/listen.js" /* webpackChunkName: "component---src-pages-showcase-new-icons-listen-js" */),
  "component---src-pages-showcase-new-icons-transcript-js": () => import("./../../../src/pages/showcase-new/icons/transcript.js" /* webpackChunkName: "component---src-pages-showcase-new-icons-transcript-js" */),
  "component---src-pages-showcase-new-index-js": () => import("./../../../src/pages/showcase-new/index.js" /* webpackChunkName: "component---src-pages-showcase-new-index-js" */),
  "component---src-pages-showcase-new-podcast-promo-js": () => import("./../../../src/pages/showcase-new/PodcastPromo.js" /* webpackChunkName: "component---src-pages-showcase-new-podcast-promo-js" */),
  "component---src-pages-terms-of-service-customer-js": () => import("./../../../src/pages/terms-of-service/customer.js" /* webpackChunkName: "component---src-pages-terms-of-service-customer-js" */),
  "component---src-pages-terms-of-service-user-js": () => import("./../../../src/pages/terms-of-service/user.js" /* webpackChunkName: "component---src-pages-terms-of-service-user-js" */),
  "component---src-pages-thegdwc-js": () => import("./../../../src/pages/thegdwc.js" /* webpackChunkName: "component---src-pages-thegdwc-js" */),
  "component---src-pages-user-terms-js": () => import("./../../../src/pages/user-terms.js" /* webpackChunkName: "component---src-pages-user-terms-js" */),
  "component---src-pages-vs-dropbox-js": () => import("./../../../src/pages/vs-dropbox.js" /* webpackChunkName: "component---src-pages-vs-dropbox-js" */),
  "component---src-pages-vs-google-drive-js": () => import("./../../../src/pages/vs-google-drive.js" /* webpackChunkName: "component---src-pages-vs-google-drive-js" */),
  "component---src-pages-vs-perforce-js": () => import("./../../../src/pages/vs-perforce.js" /* webpackChunkName: "component---src-pages-vs-perforce-js" */),
  "component---src-views-prismic-showcase-js": () => import("./../../../src/views/prismic/Showcase.js" /* webpackChunkName: "component---src-views-prismic-showcase-js" */),
  "component---src-views-prismic-types-article-js": () => import("./../../../src/views/prismic/types/Article.js" /* webpackChunkName: "component---src-views-prismic-types-article-js" */),
  "component---src-views-prismic-types-event-landing-js": () => import("./../../../src/views/prismic/types/EventLanding.js" /* webpackChunkName: "component---src-views-prismic-types-event-landing-js" */),
  "component---src-views-prismic-types-resource-landing-js": () => import("./../../../src/views/prismic/types/ResourceLanding.js" /* webpackChunkName: "component---src-views-prismic-types-resource-landing-js" */)
}

